@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;

}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;

}

.fieldGroupPlain {
  margin: 0;
  padding-left: 0px;
  padding-bottom: 6px;
  border: none;
  background-color: white;

  @media (--viewportMedium) {
    padding: 3px 0 29px 2px;
    padding: 4px 0 4px 2px;
  }
}

.list {
  margin: 0;
}

.item {
  height: 28px;
  align-items: center;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

}

.itemNoResult {
  height: auto;
  font-weight: var(--fontWeightRegular);
  font-size: 12px;
  padding: 0;
  cursor: pointer;
}

.showMoreButton {
  display: inline-block;
  margin: 12px 0;
  font-size: 12px;
  line-height: 1;
  cursor: pointer;
  font-weight: var(--fontWeightRegular);

  &:hover,
  &:focus {
    color: var(--marketplaceColor);
  }
}